import React, { Component, useGlobal } from "reactn";
import axios from "axios";
import { CONFIG } from "../../Constants/config";
import FancyCard from "../../Components/FancyCard/FancyCard";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { withNamespaces } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import Moment from "react-moment";
import moment from "moment";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

class SensorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: null,
      startDate: null,
      endDate: null,
      sensors: [],
      airly: null,

      seriesCaqi: [
        {
          name: "CAQI",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],

      seriesOutsideTemp: [
        {
          name: "C",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],

      optionsAirQuality: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
      optionsOutsideTemp: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  }

  onSelect = async (dates) => {
    this.setState({ dates });
    let startDate = dates.start.format("YYYY-MM-DD");
    let endDate = dates.end.format("YYYY-MM-DD");

    await this.setState({
      startDate: startDate,
      endDate: endDate,
    });
    console.log(startDate + " - " + endDate);

    if (this.state.startDate == null && this.state.endDate == null) {
      this.initializeGraphs();
      return;
    }

    this.updateGraphs();
  };

  daterange = function (start, end) {
    for (
      var a = [], d = new Date(start);
      d <= new Date(end);
      d.setDate(d.getDate() + 1)
    ) {
      a.push(
        (new Date(d).getDate().toString().length > 1
          ? new Date(d).getDate()
          : "0" + new Date(d).getDate()) +
          "." +
          (new Date(d).getMonth().toString().length + 1 > 1
            ? new Date(d).getMonth() + 1
            : new Date(d).getMonth() + 1)
      );
    }
    return a;
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  componentDidMount() {
    this.setGlobal({ isLoading: true });
    this.initializeGraphs();
    this.fetchData();
    // this.fetchAirly();
  }

  //ToDO : if both endDate and startDate Data is missing crash fix
  updateGraphs = async () => {
    this.endDate = this.state.endDate.toString();
    this.startDate = this.state.startDate.toString();

    let range = this.daterange(this.startDate, this.endDate);

    this.setState({
      optionsAirQuality: {
        ...this.state.optionsAirQuality,
        xaxis: {
          ...this.state.optionsAirQuality.xaxis,
          categories: range,
        },
      },
      optionsOutsideTemp: {
        ...this.state.optionsOutsideTemp,
        xaxis: {
          ...this.state.optionsOutsideTemp.xaxis,
          categories: range,
        },
      },
    });
    this.fetchData();
  };

  initializeGraphs = async () => {
    this.endDate = moment().format("YYYY-MM-DD").toString();
    this.startDate = moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
      .toString();

    let range = this.daterange(this.startDate, this.endDate);

    this.setState({
      optionsAirQuality: {
        ...this.state.optionsAirQuality,
        xaxis: {
          ...this.state.optionsAirQuality.xaxis,
          categories: range,
        },
      },
      optionsOutsideTemp: {
        ...this.state.optionsOutsideTemp,
        xaxis: {
          ...this.state.optionsOutsideTemp.xaxis,
          categories: range,
        },
      },
    });

    console.log(this.state.graphs);
  };

  fetchAirly = () => {
    if (!localStorage.getItem("selectedDevice")) {
      return;
    }
    let endpoint = null;
    let airly_id = JSON.parse(localStorage.getItem("selectedDevice")).airly_id;
    endpoint =
      "https://airapi.airly.eu/v2/measurements/installation?installationId=" +
      airly_id;

    try {
      axios
        .get(endpoint, {
          headers: {
            apikey: "mJ8pPx6uhV4tB7N3NP0Bb9pTpGCf7QqT",
          },
        })
        .then((res) => {
          let airly = {
            quality: res.data.current.indexes[0].value,
            pm1: res.data.current.values[0].value,
            pm25: res.data.current.values[1].value,
            pm10: res.data.current.values[2].value,
            pressure: res.data.current.values[3].value,
            humidity: res.data.current.values[4].value,
            temperature: res.data.current.values[5].value,
            no2: res.data.current.values[6].value,
            o3: res.data.current.values[7].value,
            road_temp: res.data.current.values[7].value,
            internal_temp: res.data.current.values[7].value,
            internal_humidity: res.data.current.values[7].value,
          };

          this.setState({ airly: airly });
          setTimeout(this.fetchAirly, 5000);
        });
    } catch {}
  };

  fetchData = () => {
    if (!localStorage.getItem("selectedDevice")) {
      return;
    }
    let endpoint =
      "spc3_sensors?uuid=" +
      JSON.parse(localStorage.getItem("selectedDevice")).UUID +
      "&from=" +
      this.startDate +
      "&to=" +
      this.endDate;

    try {
      axios
        .get(CONFIG.API_URL_V2 + endpoint, { validateStatus: false })
        .then((res) => {
          if (parseInt(res.data.STATUS_CODE) === 200) {
            this.setGlobal({ isLoading: false });

            let airly = {
              quality: res.data.DATA.latest_airly.caqi,
              pm1: res.data.DATA.latest_airly.pm1,
              pm25: res.data.DATA.latest_airly.pm25,
              pm10: res.data.DATA.latest_airly.pm10,
              pressure: res.data.DATA.latest_airly.pressure,
              humidity: res.data.DATA.latest_airly.humidity,
              temperature: res.data.DATA.latest_airly.outside_temp,
              no2: res.data.DATA.latest_airly.no2,
              o3: res.data.DATA.latest_airly.o3,
              road_temp: res.data.DATA.latest_airly.road_temp,
              internal_temp: res.data.DATA.latest_airly.internal_temp,
              internal_humidity: res.data.DATA.latest_airly.internal_humidity,
            };

            this.setState({ airly: airly });

            this.updateGraphsFromQuery(res.data.DATA);

            // setTimeout(this.fetchData, 5000);
          }
        });
    } catch {
      this.timeout = null;
    }
  };

  updateGraphsFromQuery = (data) => {
    if (data.airly) {
      // Loop over, set dates and data
      let range = [];
      let valuesCaqi = [];
      let valuesOutsideTemp = [];

      data.airly.forEach((element) => {
        range.push(element.date);
        valuesCaqi.push(element.caqi);
        valuesOutsideTemp.push(element.outside_temp);
      });

      this.setState({
        optionsAirQuality: {
          ...this.state.optionsAirQuality,
          xaxis: {
            ...this.state.optionsAirQuality.xaxis,
            categories: range,
          },
        },
        optionsOutsideTemp: {
          ...this.state.optionsOutsideTemp,
          xaxis: {
            ...this.state.optionsOutsideTemp.xaxis,
            categories: range,
          },
        },
        seriesCaqi: [
          {
            ...this.state.seriesCaqi,
            data: valuesCaqi,
          },
        ],

        seriesOutsideTemp: [
          {
            ...this.state.seriesOutsideTemp,
            data: valuesOutsideTemp,
          },
        ],
      });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="row mb-0 row-eq-height">
          {JSON.parse(localStorage.getItem("selectedDevice"))?.airly_id && (
            <>
              <div
                className={"col-lg-6 col-sm-12 col-md-12"}
                style={{ marginBottom: "38px" }}
              >
                <div className="card crm-data-card h-100">
                  <div className="card-body animated fadeIn fast">
                    <div
                      className="row"
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 className="header-title mt-0">{t("Air quality")}</h4>

                      <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                          id="AirQualityCalendarDropdown"
                          variant=""
                        >
                          <i
                            className={"mdi mdi-calendar-clock"}
                            style={{
                              color: "#50649c",
                              position: "relative",
                              fontSize: "20px",
                              right: "2px",
                            }}
                          />
                        </Dropdown.Toggle>

                        <DropdownMenu>
                          <DropdownItem href="javascript: void(0);">
                            <DateRangePicker
                              value={this.state.dates}
                              onSelect={this.onSelect}
                            />
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <ReactApexChart
                      options={this.state.optionsAirQuality}
                      series={this.state.seriesCaqi}
                      type="line"
                      height={350}
                    />
                  </div>
                </div>
                {/*end card*/}
              </div>

              <div
                className={"col-lg-6 col-sm-12 col-md-12"}
                style={{ marginBottom: "38px" }}
              >
                <div className="card crm-data-card h-100">
                  <div className="card-body animated fadeIn fast">
                    <div
                        className="row"
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                    >
                      <h4 className="header-title mt-0">{t("Outside temperature")}</h4>

                      <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                            id="AirQualityCalendarDropdown"
                            variant=""
                        >
                          <i
                              className={"mdi mdi-calendar-clock"}
                              style={{
                                color: "#50649c",
                                position: "relative",
                                fontSize: "20px",
                                right: "2px",
                              }}
                          />
                        </Dropdown.Toggle>

                        <DropdownMenu>
                          <DropdownItem href="javascript: void(0);">
                            <DateRangePicker
                                value={this.state.dates}
                                onSelect={this.onSelect}
                            />
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <ReactApexChart
                      options={this.state.optionsOutsideTemp}
                      series={this.state.seriesOutsideTemp}
                      type="line"
                      height={350}
                    />
                  </div>
                </div>
                {/*end card*/}
              </div>
            </>
          )}
        </div>
        <div className="row mb-0 row-eq-height">
          {JSON.parse(localStorage.getItem("selectedDevice"))?.airly_id && (
            <>
              <FancyCard
                size={4}
                title={t("Air quality")}
                value={this.state.airly?.quality.toFixed(2)}
                tooltip={t("Air quality description")}
                icon={"mdi-speedometer"}
                iconClass={"bg-soft-secondary"}
              />
              <FancyCard
                size={4}
                title="PM1"
                value={this.state.airly?.pm1.toFixed(2) + " µg/m\u00B3"}
                icon={"mdi-speedometer"}
                iconClass={"bg-soft-secondary"}
              />
              <FancyCard
                size={4}
                title="PM25"
                value={this.state.airly?.pm25.toFixed(2) + " µg/m\u00B3"}
                icon={"mdi-speedometer"}
                iconClass={"bg-soft-secondary"}
              />
              <FancyCard
                size={4}
                title="PM10"
                value={this.state.airly?.pm10.toFixed(2) + " µg/m\u00B3"}
                icon={"mdi-speedometer"}
                iconClass={"bg-soft-secondary"}
              />
              <FancyCard
                size={4}
                title={t("Air pressure")}
                value={this.state.airly?.pressure.toFixed(2) + " mmHg"}
                icon={"mdi-speedometer"}
                iconClass={"bg-soft-secondary"}
              />
              <FancyCard
                size={4}
                title={t("Humidity")}
                value={this.state.airly?.humidity.toFixed(2) + " %"}
                icon={"mdi-weather-rainy"}
                iconClass={"bg-soft-secondary"}
              />
              <FancyCard
                size={4}
                title={t("Outside temperature")}
                value={this.state.airly?.temperature.toFixed(2) + " \u00b0C"}
                icon={"mdi-temperature-celsius"}
                iconClass={"bg-soft-secondary"}
              />
              <FancyCard
                size={4}
                title="NO2"
                value={this.state.airly?.no2.toFixed(2) + " µg/m\u00B3"}
                icon={"mdi-speedometer"}
                iconClass={"bg-soft-secondary"}
              />
              <FancyCard
                size={4}
                title="O3"
                value={this.state.airly?.o3.toFixed(2) + " µg/m\u00B3"}
                icon={"mdi-speedometer"}
                iconClass={"bg-soft-secondary"}
              />
            </>
          )}

          <FancyCard
            size={4}
            title={t("Road temperature")}
            value={this.state.airly?.road_temp.toFixed(2) + " \u00b0C"}
            icon={"mdi-temperature-celsius"}
            iconClass={"bg-soft-secondary"}
          />

          <FancyCard
            size={4}
            title={t("Internal temperature")}
            value={this.state.airly?.internal_temp.toFixed(2) + " C"}
            icon={"mdi-temperature-celsius"}
            iconClass={"bg-soft-secondary"}
          />

          <FancyCard
            size={4}
            title={t("Internal humidity")}
            value={this.state.airly?.internal_humidity.toFixed(2) + " %"}
            icon={"mdi-weather-rainy"}
            iconClass={"bg-soft-secondary"}
          />
        </div>
      </div>
    );
  }
}

export default withNamespaces()(SensorPage);
