import React, {Component} from 'reactn';
import DataTable from 'react-data-table-component';
import {withNamespaces} from "react-i18next";
import {Select2} from "select2-react-component";
import axios from "axios";
import {CONFIG} from "../../Constants/config";

class NotificationsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    name: props.t("Date"),
                    selector: row => row.date,
                    sortable: true,
                },
                {
                    name: props.t("Device"),
                    selector: row => row.device,
                    sortable: true,
                },
                {
                    name: props.t("Error"),
                    selector: row => row.error,
                    sortable: true,
                },
                {
                    name: props.t("Is new"),
                    selector: row => row.isNew,
                    sortable: true,
                    show: false
                }
            ],

            data: []
        };
    };

    fetchNotifications = () => {
        let endpoint = 'notifications?uuid=' + JSON.parse(localStorage.getItem('selectedDevice')).UUID + "&uid="+this.global.user?.data?.id;

        try {
            axios.get(CONFIG.API_URL_V2 + endpoint, {validateStatus: false})
                .then(res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {
                        let newArray = [];

                        Object.keys(res.data.DATA).forEach(key => {
                            let obj = res.data.DATA[key];

                            newArray.push({
                                date: obj.date,
                                device: obj.device,
                                error: obj.error,
                                isNew: obj.isNew ? "✔️" : null
                            })
                        });

                        this.setState({data: newArray});
                    }
                });
        } catch {
            this.timeout = null;
        }
    }

    componentDidMount() {
        this.fetchNotifications();
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    conditionalRowStyles={[
                        {
                            when: row => row.isNew,
                            style: {
                                backgroundColor: "#ff000047"
                            }
                        }
                    ]}
                />
            </div>
        );
    }
}

export default withNamespaces()(NotificationsPage);
