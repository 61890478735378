export const TRANSLATION_CR = {
    "change_name":"Change name",
    "device_name":"Device name",
    "history_view":"History view",
    "daily_view":"Daily view",
    "choose_period":"Choose period",
    "beginning_of_the_year":"Beginning of the year",
    "last_3_months":"Last 3 months",
    "last_month":"Last month",
    "last_week":"Last week",
    "this_week":"This week",
    "total":"Total",
    "max_speed":"Max speed",
    "direction":"Direction",
    "pedestrian_statistics":"Pedestrian statistics",
    "radar_statistics":"Radarska statistika",
    "export_data":"Export data",
    "air_quality":"Air quality",
    "air_pressure":"Air pressure",
    "temperature":"Temperature",
    "debug":"Debug",
	"users":"Users",
	"blink_device_a":"Blink device A",
	"blink_device_b":"Blink device B",
	"blink_ended_a":"Blink ended device A",
	"blink_ended_b":"Blink ended device B",
	"latest_boot_a":"Latest boot device A",
	"latest_boot_b":"Latest boot device B",
	"read_device_a":"SHT21 read device A",
	"read_device_b":"SHT21 read device B",

	"dashboard":"Pregledna ploča",
	"statistics":"Statistika",
	"sensors":"Senzori",
	"power":"Napajanje",
	"contact_us":"Kontaktirajte nas",
	"Contact us":"Kontaktirajte nas",
	"log_out":"Odjava",
	"BMC":"BMC",
	"introduction_to_bmc":"Uvod u BMC",
	"intro_text":"BMC je web-sučelje koje Vam omogućava pristup i upravljanje podacima Bercman uređaja za upravljanje prometom, poput pametnog pješačkog prijelaza. Molimo vas da pročitate uvod kroz koji ćete dobiti pregled kretanja konzolom, ključnih značajki konzole i upute za prvo korištenje. Uvod će Vas voditi i kroz demonstracije različitih korisničkih slučajeva kako biste potpuno koristili prikupljene podatke.\n \nZa dodatne informacije, slobodno se obratite našem timu e-poštom na support@bercman.com. Odgovore pružamo unutar 24 sata.\n \nOstanite sigurni i zamijećeni!\n \nTeam Bercman",
	"pedestrian_count_direction_count_A_B":"Brojač prelazaka pješaka u smjeru A->B",
	"crossings_counted":"Broj prelazaka",
	"pedestrian_count_direction_count_b_a":"Brojač prelazaka pješaka u smjeru B->A",
	"average_crossing_time":"Prosječno trajanje prelaska",
	"foul_crossing_a_b":"Nepropisni prelasci u smjeru A->B",
	"foul_crossing_b_a":"Nepropisni prelasci u smjeru B->A",
	"average_waiting_time":"Prosječno vrijeme čekanja",
	"presence_trigger":"Okidač prema prisustvu",
	"long_vehicle_restriction_area":"Long-Vehicle Restriction area",
	"speed_trigger":"Okidač prema brzini",
	"speedings":"Prekomjena brzina (iznad 60km/h)",
	"wrong_way_direction_trigger":"Okidač prema kretanju u pogrešnom smjeru",
	"wrong_way_driving_detected":"Otkrivena vožnja u pogrešnom smjeru",
	"precentile_speed":"Brzina za 85. percentil",
	"radar_counting":"Radarsko brojanje",
	"pedestrian":"Pješak",
	"bicycle":"Bicikl",
	"motorbike":"Motocikl",
	"passenger_car":"Osobni automobil",
	"transporter":"Transporter",
	"truck_bus":"Kamion/Autobus",
	"long_truck":"Dugi kamion",
	"last_updated":"Posljednje ažuriranje",
	"road_temperature":"Temperatura ceste",
	"light_level":"Razina svjetla",
	"internal_temperature":"Unutarnja temperatura",
	"internal_humidity":"Unutarnja vlažnost",
	"battery_voltage":"Napon baterije",
	"input_voltage":"Ulazni napon",
	"input_amperage":"Ulazna struja",
	"input_power":"Ulazna snaga",
	"fan_speed":"Brzina ventilatora",
	"voltage":"Napon",
	"frequency":"Frekvencija",
	"active_power":"Djelatna snaga",
	"reactive_power":"Jalova snaga",
	"apparent_power":"Prividna snaga",
	"power_factor":"Stupanj korisnog djelovanja",
	"active_energy":"Djelatna energija",
	"total_kwh":"Ukupno KW/H",
	"reactive_energy":"Jalova energija",
	"management_console":"Upravljačka konzola",
	"contact_us_title":"Ukoliko imate poteškoća, slobodno nam se obratite!",
	"fill_all_details":"Molimo vas da ispunite sva polja",
	"name":"Ime",
	"email":"Adresa e-pošte",
	"subject":"Predmet",
	"message":"Poruka",
	"send_message":"Pošalji poruku",
	"message_sent":"Zahvaljujemo na kontaktu! Uskoro ćemo Vas povratno kontaktirati.",
	"outdated":"Zastarjelo",
	"back":"Prošli dan",
	"forward":"Sljedeći dan",
	"status":"Status",
	"stat_1":"Broj pješaka koji su krenuli s [A] strane pješačkog prijelaza i prešli na [B] stranu pješačkog prijelaza.",
	"stat_2":"Broj pješaka koji su krenuli s [B] strane pješačkog prijelaza i prešli na [A] stranu pješačkog prijelaza.",
	"stat_3":"Prosječno je pješacima trebalo ovoliko sekundi za prelazak u posljednjih [X]. Uključeni su prelasci u oba smjera.",
	"stat_4":"Broj pješaka koji su krenuli s [A] strane pješačkog prijelaza i nepropisno prešlo na [B] stranu pješačkog prijelaza.",
	"stat_5":"Broj pješaka koji su krenuli s [B] strane pješačkog prijelaza i nepropisno prešlo na [A] stranu pješačkog prijelaza.",
	"stat_6":"Prosjek vremena čekanja prije prelaska pješaka u sekundama. Uključeni su prelasci u oba smjera.",
	"stat_7":"Brojač detektiranih vrsta prijevoznih sredstava na cesti od početka praćenja.",
	"stat_8":"Broj vozila s detektiranom prekomjernom brzinom od početka praćenja.",
	"stat_9":"Broj detektiranih vožnji u pogrešnom smjeru od početka praćenja.",
	"stat_10":"Brzina vožnje za 85% svih vozila detektiranih na cesti pri uobičajenim cestovnim uvjetima od početka praćenja.",
	"sensors_1":"Temperatura ceste izmjerena infracrvenim termometrom.",
	"sensors_2":"Razina difuznog okolišnog osvjetljenja.",
	"sensors_3":"Internal temperature of the device.",
	"sensors_4":"Internal relative humidity of the device.",
	"sensors_5":"Razina napona baterije (maks. napon: 14.4 V).",
	"sensors_6":"Razina napona solarne ploče (maks. napon: 19.6 V).",
	"sensors_7":"Razina struje solarne ploče (maks. struja: 4.59 A).",
	"sensors_8":"Razina snage el. energije proizvedene solarnom pločom (maks. snaga: 90 W).",
	"sensors_9":"Ulazni napon doveden do glavne upravljačke ploče preko napajanja (nazivno: 12 V).",
	"power_1":"Jakost struje koju koristi uređaj.",
	"power_2":"Potrošnja električne energije uređaja.",
	"power_3":"Brzina okretaja ventilatora, gdje je 0% zaustavljeni ventilator, a 100% ventilator koji radi punom brzinom.",
	"power_4":"Napon izmjenične el. struje opskrbne mreže (VAC)",
	"power_5":"Jakost izmjenične el. struje opskrbne mreže (AAC)",
	"power_6":"Frekvencija izmjenične el. struje opskrbne mreže (nominalno: 50 Hz).",
	"power_7":"Snaga koju trošilo zaista koristi za rad zove se djelatna snaga ili aktivna snaga, a mjeri se u vatima (W).",
	"power_8":"Zbog karakteristika izmjenične struje, u određenim dijelovima električnih sustava pojavljuje se jalova ili reaktivna snaga koja ne vrši rad, a jedinična oznaka je var.",
	"power_9":"Produkt efektivnih iznosa (RMS) struje i napona zove se prividna snaga, a mjeri se u volt-amperima (VA).",
	"power_10":"Faktor snage je mjera efikasnosti korištenja ulazne snage u električnoj instalaciji. Predstavlja omjer djelatne i prividne snage.",
	"power_11":"Električna trošila napajana električnom strujom pretvaraju energiju u mehanički rad i toplinu. Taj poželjni rezultat zove se „djelatna energija“ i mjeri se u kWh. Trošila koja se sastoje isključivo od kapacitivnih krugova (poput grijača, žarulja sa žarnom niti i sl.) koriste samo djelatnu energiju.",
	"power_12":"Ukupno potrošena snaga s opskrbne mreže izražena u kWh.",
	"power_13":"Postoji niz elemenata poput motora, transformatora, reaktancije itd., čiji se rad oslanja na magnetska polja. Takva je oprema općenito induktivna te se energija iz strujne mreže koristi za stvaranje magnetskih polja, a zatim se vraća dok polja nestaju. Zbog toga koristi se više energije nego što je trošilu potrebno za rad. Ta se energija zove jalova energija te se izražava u var satima (varh).",
	"radar_options":{
		"chart":{
			"id":"basic-bar"
		},
		"xaxis":{
			"categories":[
				"Bicikl",
				"Motocikl",
				"Osobni automobil",
				"Transporter",
				"Kamion/Autobus",
				"Dugi kamion"
			]
		}
	},
	"optionsPie":{
		"chart":{
			"width":380,
			"type":"pie"
		},
		"labels":[
			"Bicikl",
			"Motocikl",
			"Osobni automobil",
			"Transporter",
			"Kamion/Autobus",
			"Dugi kamion"
		]
	},
	"locale":"hr"
}