import React, {Component} from 'reactn';
import {withNamespaces} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import {ModalBody, ModalFooter, ModalTitle} from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import {CONFIG} from "../../Constants/config";

class SetSpeedModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            speed_trigger: 0
        };
    }

    componentDidMount() {
        this.fetchSpeedTrigger();
    }

    fetchSpeedTrigger = () => {
        let endpoint = 'get_speed_trigger?uuid=' + JSON.parse(localStorage.getItem('selectedDevice')).UUID;

        try {
            axios.get(CONFIG.API_URL_V2 + endpoint, {validateStatus: false})
                .then(res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {
                        this.setState({
                            speed_trigger: res.data.DATA.speed_trigger
                        })
                    }
                })
        } catch {
            this.timeout = null;
        }
    };


    handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            this.save();
        }
    };

    save = () => {
        let endpoint = 'save_speed_trigger?uuid=' + JSON.parse(localStorage.getItem('selectedDevice')).UUID;
        axios.post(CONFIG.API_URL_V2 +endpoint, {
                'speed': this.state.speed_trigger,
            }
        ).then((res) => {
            if (res.data.STATUS_MESSAGE == "FAIL") {
                swal(this.props.t("Error!"), this.props.t(res.data.DATA.message), "error").then(r => {

                });
            } else {

                swal(this.props.t("Success!"), this.props.t("Speeding trigger changed!"), "success").then(async r => {
                    window.location.reload();
                });

            }
        });
    }

    handleChange = (event) => {
        this.setState({speed_trigger: event.target.value});
    }

    render() {
        const {t, isOpen, toggleModal} = this.props;
        return (
            <Modal show={isOpen}>
                <ModalHeader>
                    <ModalTitle>{t("Set speeding trigger")}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">
                                {t("Current speeding trigger")}</label>
                            <input type="number" className="form-control" id="exampleFormControlInput1" placeholder=""
                                value={this.state.speed_trigger}
                               onKeyDown={this.handleKeypress}
                               onChange={this.handleChange}
                            />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-default" onClick={toggleModal}>{t("Cancel")}</button>
                    <button className="btn btn-success" onClick={this.save}>{t("Set speeding trigger")}</button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withNamespaces()(SetSpeedModal);
