import React, {Component} from 'reactn';

import Moment from "react-moment";
import {SendMailQuery} from "../../Queries/SendMailQuery";
import {ReCAPTCHA} from "react-google-recaptcha";
import {withNamespaces} from "react-i18next";

class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            sensors: [],
            lastUpdated: null,
            outOfSync: false,
            name: null,
            email: null,
            subject: null,
            message: null,
            sent: false,
            errors: []
        };

        Moment.globalFormat = 'YYYY-M-D H:m:ss';
    };

    validate(){
        let errors = {};

        let isValid = true;


        if (!this.state.email) {
            isValid = false;
            errors["email"] = this.props.t("Please enter your email Address.");
        }

        if (typeof this.state.email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.email)) {
                isValid = false;

                errors["email"] = this.props.t("Please enter valid email address.");
            }

        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    handleSubmit = async () => {
        if(!this.validate()) {
            return;
        }

        try {
            const response = await SendMailQuery(this.state.name, this.state.email, this.state.subject, this.state.message);

            this.setGlobal({'isLoading': true});

            if(response && parseInt(response.status) === 200) {
                this.setState({'sent' : true});
                this.setGlobal({'isLoading': false});
            }
        } catch (e) {
            this.setGlobal({'isLoading': false});
            this.setState({showError: true});
        }
    };

    handleNameChange = (event) => {
        this.setState({name: event.target.value});
    };

    handleEmailChange = (event) => {
        this.setState({email: event.target.value});
    };

    handleSubjectChange = (event) => {
        this.setState({subject: event.target.value});
    };

    handleMessageChange = (event) => {
        this.setState({message: event.target.value});
    };

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    componentDidMount() {
        this.setState({'sent' : false});
        // this.setGlobal({'isLoading': true});
       // this.fetchData();
        this.timeout = setInterval(() => {
            this.fetchData();
        }, 1500);
    }

    fetchData = () => {

    };

    onChangeCapcha = (value) => {

    };

    render() {
        const {t} = this.props;
        return (
            <div>
                 <div className="row">
                    <div class={"col-12"}>
                        {!this.state.sent &&
                        <div className="card">
                            <div className="card-body"><h4 className="mt-0 header-title">{t("Contact us")}</h4><p
                                className="text-muted mb-3">{t("Fill all details")}</p>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="username">{t("Name")}</label>
                                            <input type="text" className="form-control" id="username"
                                                   required="" value={this.state.name}
                                                   onChange={this.handleNameChange}></input>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group"><label htmlFor="useremail">{t("E-mail")}</label>
                                            <input type="email" className="form-control" id="useremail"
                                                   value={this.state.email} onChange={this.handleEmailChange}
                                                   required=""/>
                                            <div className="text-danger">{this.state.errors.email}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group"><label htmlFor="subject">{t("Subject")}</label> <input
                                            type="text" className="form-control" id="subject" required=""
                                            value={this.state.subject} onChange={this.handleSubjectChange}/></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group"><label htmlFor="message">{t("Message")}</label>
                                            <textarea className="form-control" rows="5" id="message"
                                                      value={this.state.message}
                                                      onChange={this.handleMessageChange}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ReCAPTCHA
                                            sitekey="6LfW7fcZAAAAAEVM6gJ7_FQN0NJkWiTzW398Taa1"
                                            onChange={this.onChangeCapcha}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 text-right">
                                        <button type="submit" className="btn btn-sm btn-primary px-4"
                                                onClick={this.handleSubmit}>{t("Send message")}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        }

                        {this.state.sent &&
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                            <div className="card crm-data-card">
                                <div className="card-body animated fadeIn fast">
                                    <h2 style={{padding: 5}} className={"text-center"}>{t("Message sent!")}</h2>

                                </div>
                                {/*end card-body*/}
                            </div>
                            {/*end card*/}
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(ContactPage);
