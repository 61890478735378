export const TRANSLATION_EE = {
    "change_name":"Change name",
    "device_name":"Device name",
    "history_view":"History view",
    "daily_view":"Daily view",
    "choose_period":"Choose period",
    "beginning_of_the_year":"Beginning of the year",
    "last_3_months":"Last 3 months",
    "last_month":"Last month",
    "last_week":"Last week",
    "this_week":"This week",
    "total":"Total",
    "max_speed":"Max speed",
    "direction":"Direction",
    "pedestrian_statistics":"Pedestrian statistics",
    "radar_statistics":"Radar statistics",
    "export_data":"Export data",
    "air_quality":"Air quality",
    "air_pressure":"Air pressure",
    "temperature":"Temperature",
    "debug":"Debug",
    "users":"Users",
    "blink_device_a":"Blink device A",
    "blink_device_b":"Blink device B",
    "blink_ended_a":"Blink ended device A",
    "blink_ended_b":"Blink ended device B",
    "latest_boot_a":"Latest boot device A",
    "latest_boot_b":"Latest boot device B",
    "read_device_a":"SHT21 read device A",
    "read_device_b":"SHT21 read device B",

    dashboard : "Avaleht",
    statistics : "Statistika",
    sensors : "Andurid",
    power : "Vool",
    contact_us : "Võta meiega ühendust",
    log_out : "Logi välja",
    BMC : "BMC",
    introduction_to_bmc : "BMC tutvustus",
    intro_text : "BMC (Bercmani Haldusportaal) on veebipõhine kasutajaliides, mis võimaldab Teil näha ja hallata andmeid, mis pärinevad Teie Bercmani seadmetest. Kogu BMC-st leitava informatsiooni mõistmiseks lugege palun järgnevat tutvustust, mis annab Teile ülevaate BMC-s navigeerimise, tähtsaimate omaduste ja alustamise kohta. Samuti tutvustatakse erinevaid BMC kasutusvõimalusi, mis aitavad Teil võtta andmetest viimast.\n \n  Kui Te vajate täpsemat informatsiooni, siis võtke meiega ühendust aadressil support@bercman.com. Vastame kirjale 24 tunni jooksul.\n \nJääge ohutuks ja olge märgatud!\n \nBercmani meeskond",
    pedestrian_count_direction_count_A_B : "Jalakäijate arv Pihlakodu suunas",
    crossings_counted : "Teeületuste arv",
    pedestrian_count_direction_count_b_a : "Jalakäijate arv Lasteaia suunas",
    average_crossing_time : "Keskmine teeületuse aeg",
    foul_crossing_a_b : "Valesid teeületusi Pihlakodu suunas",
    foul_crossing_b_a : "Valesid teeületusi Lasteaia suunas",
    average_waiting_time : "Keskmine ooteaeg",
    presence_trigger : "Pikad sõidukid",
    long_vehicle_restriction_area : "Pikki sõidukeid keelualas tuvastatud",
    speed_trigger : "Kiiruseületamine",
    speedings : "Kiiruseületamisi (üle 60 km/h) tuvastatud",
    wrong_way_direction_trigger : "Vales suunas sõitmine",
    wrong_way_driving_detected : "Vales suunas sõitmisi tuvastatud",
    precentile_speed : "85nda protsentiili kiirus",
    radar_counting : "Radari loendusandmed",
    pedestrian : "Jalakäija",
    bicycle : "Jalgratas",
    motorbike : "Mootorratas",
    passenger_car : "Sõiduauto",
    transporter : "Kaubik",
    truck_bus : "Veoauto/buss",
    long_truck : "Suur veoauto",
    last_updated : "Viimati uuendatud",
    road_temperature : "Teetemperatuur",
    light_level : "Valgustustihedus",
    internal_temperature : "Sisemine temperatuur",
    internal_humidity : "Sisemine niiskus",
    battery_voltage : "Aku pinge",
    input_voltage : "Sisendpinge",
    input_amperage : "Sisendvool",
    input_power : "Sisendvõimsus",
    fan_speed : "Ventilaatori kiirus",
    voltage : "Pinge",
    frequency : "Sagedus",
    active_power : "Aktiivvõimsus",
    reactive_power : "Reaktiivvõimsus",
    apparent_power : "Näivvõimsus",
    power_factor : "Võimsustegur",
    active_energy : "Aktiivenergia",
    total_kwh : "Kokku kWh",
    reactive_energy : "Reaktiivenergia",
    management_console : "Haldusportaal",
    contact_us_title : "Kui sul on probleeme, siis võta meiega ühendust",
    fill_all_details : "Palun täitke kõik väljad",
    name : "Nimi",
    email : "E-post",
    subject : "Pealkiri",
    message : "Sõnum",
    send_message : "Saada teade",
    message_sent : "Täname! Võtame teiega peatselt ühendust!",
    outdated : "aegunud",
    back: "Eelmine päev",
    forward: "Järgmine päev",
    status: "Staatus",


    stat_1 : "Jalakäijate arv, kes liikusid suunast [A] üle ülekäiguraja suunda [B]. (24h)",
    stat_2 : "Jalakäijate arv, kes liikusid suunast [A] üle ülekäiguraja suunda [B]. (24h)",
    stat_3 : "Keskmine aeg sekundites, mille jooksul ületasid jalakäijad ülekäiguraja alates algusest. Keskmistatud mõlemas suunas. (24h)",
    stat_4 : "Jalakäijate arv, kes liikusid suunast [A] vales kohas üle tee suunda [B]. (24h)",
    stat_5 : "Jalakäijate arv, kes liikusid suunast [B] vales kohas üle tee suunda [A]. (24h)",
    stat_6 : "Keskmine aeg sekundites, mis näitab, kui kaua ootasid jalakäijad teeületust alates algusest. Keskmistatud mõlemas suunas. (24h)",
    stat_7 : "Alas viibinud kindlat tüüpi liiklejate arv alates algusest.",
    stat_8 : "Tuvastatud kiiruseületamiste arv (>55km/h). (24h)",
    stat_9 : "Tuvastatud maksimaalne kiirus",
    stat_10 : "Kiirus, millega või millest allpool liikusid 85 protsenti kõikidest liiklusvahenditest. (24h)",

    sensors_1 : "Infrapuna termomeetriga mõõdetud teetemperatuur.",
    sensors_2 : "Keskkonna valgustatuse tase.",
    sensors_3 : "Seadme sisetemperatuur.",
    sensors_4 : "Seadme suhteline siseniiskus.",
    sensors_5 : "Aku pinge (maksimaalselt 14,4 V).",
    sensors_6 : "Päikesepaneeli pinge (maksimaalselt 19,6 V).",
    sensors_7 : "Päikesepaneeli voolutugevus (maksimaalselt 4,59 A).",
    sensors_8 : "Päikesepaneeli poolt toodetud võimsus (maksimaalselt 90 W).",
    sensors_9 : "Seadme põhiplaadi sisendpinge toiteplokist (nominaalpinge 12 V)",

    power_1 : "Seadme poolt kasutatav voolutugevus.",
    power_2 : "Seadme ajaühikus tarbitav elektrienergia hulk.",
    power_3 : "Ventilaatori kiiruse tase, 0% tähendab peatunud ventilaatorit ja 100% täisvõimsust.",
    power_4 : "Elektrivõrgu vahelduvvoolu pinge.",
    power_5 : "Elektrivõrgu vahelduvvoolu tugevus.",
    power_6 : "Elektrivõrgu vahelduvvoolu sagedus (nominaalsagedus 50 Hz).",
    power_7 : "Aktiivvõimsus on vooluahelas reaalselt tarbitav või sealt eralduv võimsus. Seda mõõdetakse vattides (W). Tegemist on terve elektrisüsteemi võimsusega, mille pealt toimivad seadmed ja toimub tarve.",
    power_8 : "Reaktiivvõimsus põhjustab vahelduvvoolu elektriahela elementide vahel võnkuva või sellele mõjuva reaktiivenergia. Mõõdetakse varrides (var).",
    power_9 : "Näivvõimsus on aktiivvõimsuse ja reaktiivvõimsuse geomeetriline summa. Mõõdetakse voltamprites (VA).",
    power_10 : "Võimsustegur näitab, kui efektiivselt kasutab seade sissetulevat voolu. Tegemist on aktiivvõimsuse ja näivvõimsuse suhtega.",
    power_11 : "Aktiivenergia on energia, mis on täielikult muundatav mõnda teist liiki energiaks (nt valguseks või soojuseks). Vahelduvvoolu puhul tarbivad ainult aktiivenergiat aktiivtakistusega tarbijad, näiteks hõõglambid. Mõõdetakse kilovatt-tundides (kWh).",
    power_12 : "Kogu vooluvõrgust tarbitav energiahulk kilovatt-tundides (kWh).",
    power_13 : "Reaktiivenergia kirjeldab vahelduvvoolu elektriahela elementide vahel võnkuvat energiahulka. Vooluvõrgus on erinevaid reaktiivtakistusega elemente, näiteks elektrimootorid, kondensaatorid, trafod jms, mille mahtuvuslikest ja induktiivsetest omadustest tuleneb soovimatu nähtusena reaktiivenergia, mis tekitab elektrienergia ülekandmisel lisakadusid. Mõõdetakse varrides (VAR).",
    radar_options: {
        chart: {
            id: "basic-bar"
        },
        xaxis: {
            categories: ['Jalgratas', 'Mootorratas', 'Sõiduauto', 'Kaubik', 'Buss', 'Veoauto']
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    orientation: 'horizontal',
                    position: 'top' // bottom/center/top
                }
            }
        },
        dataLabels: {
            style: {
                colors: ['#b0b0b0']
            },
            offsetY: -20, // play with this value
        },
    },
    optionsPie: {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: ['Jalgratas', 'Mootorratas', 'Sõiduauto', 'Kaubik', 'Buss', 'Veoauto'],

    },
    locale: "et",
};
