import React, {Component} from 'reactn';
import axios from 'axios';
import {CONFIG} from "../../Constants/config";
import FancyCard from "../../Components/FancyCard/FancyCard";
import * as moment from "moment";
import {withNamespaces} from "react-i18next";

class PowerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            sensors: []
        };

    };

    componentDidMount() {
        this.setGlobal({'isLoading': true});
        this.fetchData();
    }

    fetchData = () => {
        if(!localStorage.getItem('selectedDevice')) {return}
        let endpoint = 'spc3_power?uuid=' + JSON.parse(localStorage.getItem('selectedDevice')).UUID;

        try {
            axios.get(CONFIG.API_URL_V2 + endpoint, { validateStatus: false })
                .then(res => {
                    if (parseInt(res.data.STATUS_CODE) === 200) {
                        this.setState({sensors: res.data.DATA});
                        this.setState({lastUpdated: res.data.DATA?.created_at});
                        this.setGlobal({'isLoading': false});
                        setTimeout(this.fetchData, 5000);
                    }
                });
        } catch {
            this.timeout = null;
        }

    };

    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="row mb-0 row-eq-height">
                    <FancyCard
                        size={4}
                        title={t("Voltage")}
                        value={(this.state.sensors?.voltage ? this.state.sensors.voltage.toFixed(2) : 0) + " V"}

                        icon={"mdi-power-socket"}
                        iconClass={"bg-soft-secondary"}
                        tooltip={t("Voltage tooltip")}
                    />

                    <FancyCard
                        size={4}
                        title={t("Frequency")}
                        value={(this.state.sensors?.frequency ? this.state.sensors.frequency.toFixed(2) : 0) + " Hz"}

                        icon={"mdi-power-socket"}
                        iconClass={"bg-soft-secondary"}
                        tooltip={t("Frequency tooltip")}
                    />

                    <FancyCard
                        size={4}
                        title={t("Active power")}
                        value={(this.state.sensors?.active_power ? this.state.sensors.active_power.toFixed(2) : 0) + " W"}

                        icon={"mdi-power-socket"}
                        iconClass={"bg-soft-secondary"}
                        tooltip={t("Active power tooltip")}
                    />

                    <FancyCard
                        size={4}
                        title={t("Reactive power")}
                        value={(this.state.sensors?.reactive_power ? this.state.sensors.reactive_power.toFixed(2) : 0) + " var"}

                        icon={"mdi-power-socket"}
                        iconClass={"bg-soft-secondary"}
                        tooltip={t("Reactive power tooltip")}
                    />

                    <FancyCard
                        size={4}
                        title={t("Apparent power")}
                        value={(this.state.sensors?.apparent_power ? this.state.sensors.apparent_power.toFixed(2) : 0) + " VA"}

                        icon={"mdi-power-socket"}
                        iconClass={"bg-soft-secondary"}
                        tooltip={t("Apparent power tooltip")}
                    />

                    <FancyCard
                        size={4}
                        title={t("Power factor")}
                        value={(this.state.sensors?.power_factor ? this.state.sensors.power_factor.toFixed(2) : 0) + ""}

                        icon={"mdi-power-socket"}
                        iconClass={"bg-soft-secondary"}
                        tooltip={t("Power factor tooltip")}
                    />

                    <FancyCard
                        size={4}
                        title={t("Active energy")}
                        value={(this.state.sensors?.active_energy ? this.state.sensors.active_energy.toFixed(2) : 0) + " kWh"}

                        icon={"mdi-power-socket"}
                        iconClass={"bg-soft-secondary"}
                        tooltip={t("Active energy tooltip")}
                    />

                    <FancyCard
                        size={4}
                        title={t("Total KW/H")}
                        value={(this.state.sensors?.total_kWh ? this.state.sensors.total_kWh.toFixed(2) : 0) + " kWh"}

                        icon={"mdi-power-socket"}
                        iconClass={"bg-soft-secondary"}
                        tooltip={t("Total KW/H tooltip")}
                    />

                    <FancyCard
                        size={4}
                        title={t("Reactive energy")}
                        value={(this.state.sensors?.reactive_energy ? this.state.sensors.reactive_energy.toFixed(2) : 0) + " varh"}

                        icon={"mdi-power-socket"}
                        iconClass={"bg-soft-secondary"}
                        tooltip={t("Reactive energy tooltip")}
                    />

                </div>
            </div>
        );
    }
}

export default withNamespaces()(PowerPage);
