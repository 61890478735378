import './App.css';
import LoginPage from "./Pages/Login/LoginPage";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import React from 'reactn';
import MainPage from "./Pages/Main/MainPage";
import {PrivateRoute} from "./Components/PrivateRoute/PrivateRoute";

function App() {
    if(!localStorage.getItem('lang')) {
        localStorage.setItem('lang', 'en');
        localStorage.setItem('i18nextLng', 'en');
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={LoginPage}/>
                <PrivateRoute exact path="/" component={MainPage}/>
                <PrivateRoute exact path="/dashboard" component={MainPage}/>
                <PrivateRoute exact path="/statistics" component={MainPage}/>
                <PrivateRoute exact path="/sensors" component={MainPage}/>
                <PrivateRoute exact path="/power" component={MainPage}/>
                <PrivateRoute exact path="/status" component={MainPage}/>
                <PrivateRoute exact path="/debug" component={MainPage}/>
                <PrivateRoute exact path="/users" component={MainPage}/>
                <PrivateRoute exact path="/contact-us" component={MainPage}/>
                <PrivateRoute exact path="/notifications" component={MainPage}/>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
