import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationEE from './locales/ee/translation.json';
import translationCR from './locales/cr/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    ee: {
        translation: translationEE
    },
    cr: {
        translation: translationCR
    }
};

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
};

i18n
    .use(LanguageDetector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        detection: DETECTION_OPTIONS,
        resources,
        fallbackLng: "en",


        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;