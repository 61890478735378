import React, { Component, useGlobal } from "reactn";
import { css } from "styled-components";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { withNamespaces } from "react-i18next";
// import Tooltip from "react-power-tooltip";
import ReactTooltip from "react-tooltip";
import Tooltip from "@mui/material/Tooltip";

class FancyCard extends Component {
  hoursPerDay = 24;
  time = [];

  constructor(props) {
    super(props);

    var formattedTime;
    for (let i = 0; i < this.hoursPerDay + 1; i++) {
      //fill in all of the hours
      formattedTime = moment().subtract(i, "hours").format("H") + ":00"; //give the time in format X AM/PM
      this.time.unshift(formattedTime); //add to beginning of array
    } //do this for all 24 hours
    this.state = {
      canReboot: true,
      isRebootiong: false,
      options: {
        chart: {
          type: "area",
          height: 75,
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },

        grid: {
          show: false,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },

        xaxis: {
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          categories: this.time,
          labels: {
            show: false,
          },
        },
        colors: this.props.chartColor,
      },

      options2: {
        chart: {
          type: "area",
          height: 75,
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },

        grid: {
          show: false,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },

        xaxis: {
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          categories: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
          labels: {
            show: false,
          },
        },
        colors: this.props.chartColor,
        showTooltip: false,
      },
    };
  }

  showTooltip = (bool) => {
    this.setState({ showTooltip: bool });
  };

  componentDidMount() {}

  rebootOrdered = async () => {
    await this.setState({ canReboot: false });
    await this.setState({ isRebooting: true });

    setTimeout(() => {
      this.setState({ canReboot: true });
      this.setState({ isRebooting: false });
    }, 10000);
  };

  render() {
    const { t } = this.props;
    const datatip = this.props.tooltip;
    return (
      // ToDO: tooltip and title in line
      <div
        className={"col-lg-" + this.props.size + " col-sm-12 col-md-12"}
        style={{ marginBottom: "38px" }}
      >
        <div className="card crm-data-card h-100">
          <div className="card-body animated fadeIn fast">
            <div
              className="row"
              data-html={true}
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <h4
                className="header-title mt-0"
                style={{position: "relative", top: "-3px" }}
              >
                {this.props.title}
              </h4>
              {this.props.tooltip && (
                <Tooltip title={datatip}>
                  <i
                    className={"mdi mdi-cloud-question 4x p5"}
                    style={{
                      position: "relative",
                      fontSize: "20px",
                      top: "-18px",
                    }}
                  />
                </Tooltip>
              )}
            </div>
            <div className="row">
              <div className="col-4 align-self-center">
                <div className="data-icon">
                  <i
                    className={
                      "mdi " +
                      this.props.icon +
                      " rounded-circle " +
                      this.props.iconClass
                    }
                  />
                </div>
              </div>
              {/* end col*/}
              {!this.props.error && (
                <div className="col-8">
                  {this.props.value_small && <h4>{this.props.value}</h4>}
                  {this.props.value_small && <h5>{this.props.value1}</h5>}
                  {this.props.value_small && <h5>{this.props.value2}</h5>}

                  {!this.props.value_small && <h3>{this.props.value}</h3>}
                  {!this.props.value_small && <h4>{this.props.value1}</h4>}
                  {!this.props.value_small && <h4>{this.props.value2}</h4>}
                  <p className="text-muted font-12 mb-0">
                    {this.props.subtitle}
                  </p>
                </div>
              )}
              {this.props.error && (
                <div className="col-8">
                  {this.props.value_small && (
                    <h4 style={{ color: "red" }}>{this.props.value}</h4>
                  )}
                  {this.props.value_small && (
                    <h5 style={{ color: "red" }}>{this.props.value1}</h5>
                  )}
                  {this.props.value_small && (
                    <h5 style={{ color: "red" }}>{this.props.value2}</h5>
                  )}

                  {!this.props.value_small && (
                    <h3 style={{ color: "red" }}>{this.props.value}</h3>
                  )}
                  {!this.props.value_small && (
                    <h4 style={{ color: "red" }}>{this.props.value1}</h4>
                  )}
                  {!this.props.value_small && (
                    <h4 style={{ color: "red" }}>{this.props.value2}</h4>
                  )}
                  <p className="text-muted font-12 mb-0">
                    {this.props.subtitle}
                  </p>
                </div>
              )}
              {/* end col*/}
            </div>
            {/* end row*/}
          </div>
          {/*end card-body*/}
          {this.props.chart && this.props.showChart && this.state.options2 && (
            <div id="chart">
              <ReactApexChart
                options={this.state.options2}
                series={this.props.chart}
                type="area"
                height={75}
              />
            </div>
          )}
        </div>

        {/*end card*/}
      </div>
    );
  }
}

export default withNamespaces()(FancyCard);
