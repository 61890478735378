import React, {Component} from 'reactn';
import {withNamespaces} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import {ModalBody, ModalFooter, ModalTitle} from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import {CONFIG} from "../../Constants/config";

class PasswordChangeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPassword: null,
            password: null,
            password2: null
        };
    }

    handleCurrentPasswordChange = (event) => {
        this.setState({currentPassword: event.target.value});
    }
    handlePasswordChange = (event) => {
        this.setState({password: event.target.value});
    }
    handlePassword2Change = (event) => {
        this.setState({password2: event.target.value});
    }

    handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            this.save();
        }
    };

    save = () => {
        axios.post(CONFIG.API_URL_V2 + "change_password", {
                'id': this.global.user.data.id,
                'current_password': this.state.currentPassword,
                'password': this.state.password,
                'password2': this.state.password2,

            }
        ).then((res) => {
            if (res.data.STATUS_MESSAGE == "FAIL") {
                swal(this.props.t("Error!"), this.props.t(res.data.DATA.message), "error").then(r => {

                });
            } else {

                swal(this.props.t("Success!"), this.props.t("Password changed!"), "success").then(async r => {
                    console.log("Changed!");
                    await this.setGlobal({'passwordChanged': true});
                    this.props.overrideForce();
                    this.props.toggleModal();
                });

            }
        });
    }


    render() {
        const {t, isOpen, toggleModal, force, overrideForce} = this.props;
        return (
            <Modal show={isOpen}>
                <ModalHeader>
                    <ModalTitle>{t("Change your password")}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">
                                {t("Current password")}</label>
                            <input type="password" className="form-control" id="exampleFormControlInput1" placeholder=""
                                   value={this.state.currentPassword}
                                   onKeyDown={this.handleKeypress}
                                   onChange={this.handleCurrentPasswordChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">
                                {t("New password")}</label>
                            <input type="password" className="form-control" id="exampleFormControlInput1" placeholder=""
                                   value={this.state.password}
                                   onKeyDown={this.handleKeypress}
                                   onChange={this.handlePasswordChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">
                                {t("Repeat password")}</label>
                            <input type="password" className="form-control" id="exampleFormControlInput1" placeholder=""
                                   value={this.state.password2}
                                   onKeyDown={this.handleKeypress}
                                   onChange={this.handlePassword2Change}
                            />
                        </div>

                    </form>
                </ModalBody>
                <ModalFooter>
                    {!force &&
                        <button className="btn btn-default" onClick={toggleModal}>{t("Cancel")}</button>
                    }
                    <button className="btn btn-success" onClick={this.save}>{t("Change password")}</button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withNamespaces()(PasswordChangeModal);
